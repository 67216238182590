import React from "react";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";

import EmailIcon from "../images/email-icon.png";
import FbIcon from "../images/fb-icon.png";
import PhoneIcon from "../images/phone-icon.png";

const Footer = () => (
  <StyledFooter>
    <NavTitle href="#home">
      Tautas <br />
      Roks
    </NavTitle>
    <section>
      <AnchorLink href="#about">About</AnchorLink>
      <AnchorLink href="#gigs">Gigs</AnchorLink>
      <AnchorLink href="#media">Media</AnchorLink>
      <AnchorLink href="#contact">
        <NavIcon src={EmailIcon} alt="email icon" />
      </AnchorLink>
      <a href="tel:07810524391">
        <NavIcon src={PhoneIcon} alt="phone icon" />
      </a>
      <a href="https://www.facebook.com/Tautas-Roks-13819437786">
        <NavIcon src={FbIcon} alt="facebook icon" />
      </a>
    </section>
  </StyledFooter>
);

const StyledFooter = styled.footer`
  position: relative;
  float: left;
  width: 100%;
  background-color: #ac3ff7;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  align-items: center;

  section {
    display: flex;
    flex-direction: column;

    a {
      font-family: "Amatic SC", cursive;
      font-size: 1.75rem;
      color: #fff;

      a:hover {
        color: #fff;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;

    section {
      flex-direction: row;

      a {
        margin-left: 16px;
      }
    }
  }
`;

const NavTitle = styled(AnchorLink)`
  display: flex;
  text-align: center;
  line-height: 32px;
  font-size: 2.5rem;
  color: #fff;
  font-family: "Amatic SC", cursive;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const NavIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export default Footer;
