import React from "react";
import { Helmet } from "react-helmet";
import Nav from "../components/nav";
import Hero from "../components/hero";
import MainContent from "../components/main-content";
import ContactForm from "../components/contact-form";
import Gallery from "../components/gallery";
import Footer from "../components/footer";

const SEO_TITLE = "Tautas Roks";
const SEO_DESCRIPTION =
  "An energetic folk rock band based in Northamptonshire and Essex. Started in 2005, Tautas Roks are an energetic band who are making waves on the Ceilidh scene. Perfect for your Festival, Wedding or any other event you have on.";
const SEO_KEYWORDS = "Folk rock, English folk, Ceilidh, Band, Northamptonshire, Essex, London, Midlands, Wedding, Wedding Band, Event, Event Band";

const Homepage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{SEO_TITLE}</title>
      <meta name="description" content={SEO_DESCRIPTION} />
      <meta name="keywords" content={SEO_KEYWORDS} />
      <meta property="og:title" content={SEO_TITLE} />
      <meta property="og:description" content={SEO_DESCRIPTION} />
      <meta property="twitter:title" content={SEO_TITLE} />
      <meta property="twitter:description" content={SEO_DESCRIPTION} />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
    </Helmet>
    <Nav />
    <Hero />
    <MainContent />
    <ContactForm />
    <Gallery />
    <Footer />
  </>
);

export default Homepage;
