import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";

import FullBandHero from "../images/full-band-hero.jpg";
import AndyMain from "../images/Andy.jpeg";
import KrisMain from "../images/Kris.jpeg";
import MoMain from "../images/Mo.jpg";

const Hero = () => (
  <HeroContainer id="home">
    <Fade down>
      <Diagonal>
        <img src={AndyMain} alt="Andy  Care" />
      </Diagonal>
    </Fade>
    <Fade up>
      <Diagonal>
        <img src={KrisMain} alt="Kris Fisher" />
      </Diagonal>
    </Fade>
    <Fade down>
      <Diagonal>
        <img src={MoMain} alt="Mo Fisher" />
      </Diagonal>
    </Fade>
    <Diagonal>
      <img src={FullBandHero} alt="Tautas Roks" />
    </Diagonal>
  </HeroContainer>
);

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  background: black;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

const Diagonal = styled.div`
  position: relative;
  float: left;
  height: 100vh;
  transform: skew(-10deg);
  margin-left: -100px;

  img {
    height: 100%;
    width: auto;
  }

  @media (min-width: 768px) {
    margin-left: -55px;
  }
`;

export default Hero;
