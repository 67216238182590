import React from "react";
import styled from "styled-components";

const MainContent = () => (
  <PrimaryContent>
    <AboutSection id="about">
      <h1>Tautas Roks</h1>
      <p>
        An energetic folk rock band based in Northamptonshire and Essex. Started
        in 2005, Tautas Roks are an energetic band who are making waves on the
        Ceilidh scene, merging classic English folk tunes with a mixture of
        genres to give a lively, full sound that makes an impact on even the
        biggest venue. They are perfect for your Festival, Wedding or any other
        event you have on.
      </p>
      <p>
        The line-up has changed over the years, although the core of the band
        has remained the same, consisting of Kris Fisher (melodeons), Andy Care
        (guitars) and Mo Fisher (bass). We often go out as a trio, pulling in a
        drummer when the organiser requests a more rockier sound.
      </p>
      <p>
        Past performances include Rudolstadt Festival (Germany), Towersey Folk
        Festival, Sidmouth Folk Week, Broadstairs Folk Week, Warwick Folk
        Festival, Raunds Music Festival and The Music Barn, to name but a few.
      </p>
    </AboutSection>
    <GigsSection id="gigs">
      <h2>Gig Calendar</h2>
      <div className="gig-box">
        <div className="date-box">
          <h3>28</h3>
          <h4>April</h4>
        </div>
        <div className="gig-info-box">
          <h4>Raunds Music Festival</h4>
          <a href="https://raundsfestival.com/tickets">Get tickets</a>
        </div>
      </div>
      <div className="gig-box">
        <div className="date-box">
          <h3>29</h3>
          <h4>July</h4>
        </div>
        <div className="gig-info-box">
          <h4>Private function</h4>
        </div>
      </div>
      <div className="gig-box">
        <div className="date-box">
          <h3>10</h3>
          <h4>August</h4>
        </div>
        <div className="gig-info-box">
          <h4>Sidmouth Folk Week</h4>
          <a href="https://sidmouthfolkfestival.co.uk/tickets/">Get tickets</a>
        </div>
      </div>
      <div className="gig-box">
        <div className="date-box">
          <h3>10</h3>
          <h4>September</h4>
        </div>
        <div className="gig-info-box">
          <h4>Bromyard Folk Festival</h4>
          <a href="https://bromyardfolkfestival.co.uk/tickets/">Get tickets</a>
        </div>
      </div>
      <div className="gig-box">
        <div className="date-box">
          <h3>30</h3>
          <h4>September</h4>
        </div>
        <div className="gig-info-box">
          <h4>Saxbys Cider & Sausage Fest</h4>
          <a href="https://www.saxbyscider.co.uk/cider-parties/2023/septcands-g2lnp">Get tickets</a>
        </div>
      </div>
    </GigsSection>
  </PrimaryContent>
);

const PrimaryContent = styled.div`
  float: left;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 32px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 32px 64px;
  }
`;

const AboutSection = styled.section`
  width: 100%;

  @media (min-width: 768px) {
    width: 48%;
  }
`;

const GigsSection = styled.section`
  width: 100%;

  .gig-box {
    display: flex;
    flex-direction: row;
    background: rgba(255, 255, 255, 0.8);
    color: black;
    padding: 16px;
    border-bottom: 1px solid black;
  }

  .gig-box h3,
  h4 {
    margin-bottom: 0 !important;
    font-size: 1.25rem !important;
  }

  .gig-box a {
    font-family: "Amatic SC", cursive;
    font-size: 1.25rem;
  }

  .date-box {
    border-right: 1px solid black;
    padding-right: 8px;
    width: 80px;
  }

  .gig-info-box {
    margin-left: 8px;
    text-align: left;
  }

  .gig-info-box p,
  h4 {
    margin-bottom: 0 !important;
  }

  @media (min-width: 768px) {
    width: 48%;

    #gigsContainer {
      width: 500px;
    }

    .gig-box h3 {
      font-size: 2rem !important;
    }

    .gig-box h4 {
      font-size: 1.5rem !important;
    }

    .date-box {
      width: 100px;
    }
  }
`;

export default MainContent;
