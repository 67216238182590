import React, { useState, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { CookieBanner } from "@palmabit/react-cookie-law";
import Cookies from "js-cookie";
import ReactGA from "react-ga";
import { Homepage, PrivacyPolicy } from "./pages";

import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const [canTrackUser, setCanTrackUser] = useState(false);
  const [isGaInitialised, setIsGaInitialised] = useState(false);

  useEffect(() => {
    // Are we allowed to track the user?
    if (
      canTrackUser ||
      (Cookies.get("rcl_consent_given") &&
        Cookies.get("rcl_preferences_consent") &&
        Cookies.get("rcl_statistics_consent"))
    ) {
      if (!canTrackUser) setCanTrackUser(true); // avoids needing to read cookies again

      // Only init GA once
      if (!isGaInitialised) {
        ReactGA.initialize("(UA-128052804-1");
        setIsGaInitialised(true);
        return;
      }

      // Track the locaton in GA
      ReactGA.pageview(window.location.pathname);
    }
  }, [canTrackUser, isGaInitialised]);

  return (
    <>
      <CookieBanner
        message="We use cookies to understand your site usage. That way we can tailor the site navigation, content and marketing to your specific needs."
        wholeDomain={true}
        policyLink="/privacy-policy"
        styles={{
          dialog: {
            backgroundColor: "#ac3ff7",
            padding: "16px",
            bottom: "0",
            position: "fixed",
            zIndex: "1",
            width: "100%",
          },
          message: { color: "#fff", marginBottom: "8px" },
          policy: { color: "#fff", textDecoration: "underline" },
          button: {
            color: "#fff",
            backgroundColor: "#d4c084",
            border: "none",
            margin: "8px",
            padding: "6px",
          },
        }}
      />
      <BrowserRouter>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/">
          <Homepage />
        </Route>
      </BrowserRouter>
    </>
  );
};

export default App;
