import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";

import "react-image-gallery/styles/css/image-gallery.css";

import ChippenhamVideo from "../videos/chippers.mp4";

const NUM_GALLERY_IMAGES = 21;

const Gallery = () => {
  const images = [];

  useEffect(() => {
    function fetchImages() {
      for (let i = NUM_GALLERY_IMAGES; i >= 1; i--) {
        const img = require(`../images/gallery/image${i}.jpg`);
        images.push({
          original: img,
          thumbnail: img,
        });
      }
    }

    fetchImages();
  }, [images]);

  return (
    <GalleryWrapper id="media">
      <h2>Media</h2>
      <video controls>
        <source src={ChippenhamVideo} type="video/mp4" />
      </video>
      <ImageGallery
        items={images}
        showFullscreenButton={false}
        showPlayButton={false}
        lazyLoad={true}
      />
    </GalleryWrapper>
  );
};

const GalleryWrapper = styled.div`
  position: relative;
  float: left;
  width: 100%;
  padding: 32px;
  text-align: center;

  video {
    max-width: 650px;
    width: 100%;
    margin-bottom: 16px;
  }

  .image-gallery {
    max-width: 800px;
    margin: 0 auto;
  }

  .image-gallery-swipe {
    max-width: 650px;
    margin: 0 auto;
  }

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    width: 40px;
  }
`;

export default Gallery;
