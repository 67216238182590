import React, { useState } from "react";
import styled from "styled-components";
import { Navbar } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";

import EmailIcon from "../images/email-icon.png";
import FbIcon from "../images/fb-icon.png";
import PhoneIcon from "../images/phone-icon.png";

const Nav = () => {
  const [isMobileNavExpanded, setIsMobileNavExpanded] = useState(false);

  return (
    <>
      <StyledNavbar expand="md" variant="dark" expanded={isMobileNavExpanded}>
        <NavTitle href="#home" onClick={() => setIsMobileNavExpanded(false)}>
          Tautas <br />
          Roks
        </NavTitle>
        <Navbar.Toggle
          onClick={() => setIsMobileNavExpanded(!isMobileNavExpanded)}
        />
        <Navbar.Collapse className="justify-content-end nav-items">
          <AnchorLink
            href="#about"
            onClick={() => setIsMobileNavExpanded(false)}
          >
            About
          </AnchorLink>
          <AnchorLink
            href="#gigs"
            onClick={() => setIsMobileNavExpanded(false)}
          >
            Gigs
          </AnchorLink>
          <AnchorLink
            href="#media"
            onClick={() => setIsMobileNavExpanded(false)}
          >
            Media
          </AnchorLink>
          <AnchorLink
            href="#contact"
            onClick={() => setIsMobileNavExpanded(false)}
          >
            <NavIcon src={EmailIcon} alt="email icon" />
          </AnchorLink>
          <a
            href="tel:07810524391"
            onClick={() => setIsMobileNavExpanded(false)}
          >
            <NavIcon src={PhoneIcon} alt="phone icon" />
          </a>
          <a href="https://www.facebook.com/Tautas-Roks-13819437786">
            <NavIcon src={FbIcon} alt="facebook icon" />
          </a>
        </Navbar.Collapse>
      </StyledNavbar>
    </>
  );
};

const StyledNavbar = styled(Navbar)`
  position: absolute;
  z-index: 1;
  width: 100%;
  align-items: flex-start;
  padding: 0;

  .navbar-toggler {
    margin: 16px;
    z-index: 1;
  }

  .navbar-collapse {
    position: absolute;
    top: 0;
    left: 0;
    background: #ac3ff7;
    width: 100%;
    padding: 105px 0 16px 0;
  }

  a {
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
    margin-right: 16px;
    font-family: "Amatic SC", cursive;
    width: 100%;
    float: left;
    text-align: center;

    :hover {
      color: #fff;
    }
  }

  @media (min-width: 768px) {
    a {
      width: auto;
      float: auto;
      text-align: left;
      margin-top: 16px;
    }

    .navbar-collapse {
      position: relative;
      top: auto;
      left: auto;
      background: none;
      width: auto;
      padding: 0;
    }
  }
`;

const NavTitle = styled(AnchorLink)`
  display: flex;
  font-family: "Amatic SC", cursive;
  text-align: center;
  line-height: 48px;
  z-index: 1;

  && {
    font-size: 3.5rem;
    margin: 16px;
    width: auto;
  }
`;

const NavIcon = styled.img`
  width: 35px;
  height: 35px;
`;

export default Nav;
