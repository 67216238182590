import React from "react";
import styled from "styled-components";

const ContactForm = () => {
  return (
    <FormWrapper id="contact">
      <h2>Get In Touch</h2>
      <section>
        <p>
          We'd love to hear from you! Please get in touch to discuss any
          bookings, or if you would like to find out more about us. You can
          email Kris at{" "}
          <a href="mailto:kristaps@tautasroks.co.uk">
            kristaps@tautasroks.co.uk
          </a>
          {", "}
          or give Mo a ring on <a href="tel:07810524391">07810 524391</a>.
        </p>
      </section>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  background-color: #ac3ff7;
  padding: 32px;
  color: #fff;

  a {
    color: #fff;
  }

  section {
    max-width: 600px;
    margin: 0 auto;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    border: none;
    border-bottom: 1.5px solid #d4c084;
    padding: 16px 0;
    margin: 8px 0;
    background: none;
    color: #fff;

    ::placeholder {
      color: #fff;
    }
  }

  input[type="submit"] {
    border: none;
    width: 150px;
    margin: 0 auto;
    background-color: #d4c084;
    color: #fff;
    padding: 16px;
    border-radius: 5px;
  }

  input[type="submit"]:hover {
    background-color: #d4c0844f;
  }
`;

export default ContactForm;
